import React, { useState, useContext, useEffect } from "react";
import { useNavigate } from 'react-router-dom';

import {
  DataGrid,
  GridColDef,
  GridSelectionModel,
  jaJP,
} from "@mui/x-data-grid";
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import moment from "moment";

import { ReloadContext } from "../../context/ReloadContext";
import { renderCellExpand } from "../Common/GridCellExpand";
import { postSearchJack } from "../../api/jack";
import { JACK_STATUS } from "../../const/index";
import { JackData, JackDataSet, JackSearchParam } from "../../types/WebData";
import JackEditDialog from './JackEditDialog';
import DeleteDialog from "../Common/DeleteDialog";

/**
 * DataGrid用にデータを編集
 *
 * @param {JackDataSet[]} jackDatas
 * @return {*} 
 */
const makeGridData = (jackDatas: JackDataSet[]) => {
  return jackDatas.map((jack) => {
    // 日付フォーマット
    const jackHaishinbi = moment(jack.jack_haishinbi);
    const jackKaishijikan = moment(jack.jack_kaishijikan);
    const updateDate = moment(jack.update_dt);
    return {
      ...jack,
      jack_haishinbi: jackHaishinbi.format("YYYY/MM/DD"),
      jack_kaishijikan: jackKaishijikan.format("HH:mm:ss"),
      update_dt: updateDate.format("YYYY/MM/DD"),
      is_delete: jackKaishijikan.subtract(1, 'day').isBefore(moment()),
    };
  });
};

/**
 * 全件データを１ページ分の行にsliceしている
 * 値に単位をつけるなどのデータの編集も、ここで１ページ分のみ行う
 */
const loadServerRows = (
  page: number,
  pageSize: number,
  allRows: JackDataSet[]
): Promise<JackDataSet[]> =>
  new Promise<JackDataSet[]>((resolve) => {
    if (!allRows) return;
    resolve(
      // １ページ分にsliceした後に、データを編集するfunctionに渡している
      makeGridData(allRows.slice(page * pageSize, (page + 1) * pageSize))
    );
  });

const useQuery = (page: number, pageSize: number, allRows: any[]) => {
  const [rowCount, setRowCount] = React.useState<number | undefined>(undefined);
  const [data, setData] = React.useState<JackDataSet[]>([]);

  useEffect(() => {
    let active = true;

    setRowCount(undefined);
    loadServerRows(page, pageSize, allRows).then((newRows) => {
      if (!active) {
        return;
      }
      setData(newRows);
      setRowCount(allRows.length);
    });

    return () => {
      active = false;
    };
  }, [page, pageSize, allRows]);

  return { data, rowCount };
};

interface RowsState {
  page: number;
  pageSize: number;
}

type Props = {
  searchParam: JackSearchParam | null;
  setSelectedRow: React.Dispatch<React.SetStateAction<JackDataSet | null>>;
  setload: React.Dispatch<React.SetStateAction<boolean>>
};

/**
 * 媒体一覧コンポーネント
 *
 * @param {Props} { searchParam, setSelectedRow }
 * @return {*} 
 */
const JackGrid = ({ searchParam, setSelectedRow, setload }: Props) => {
  const navigate = useNavigate();
  const reloadContext = useContext(ReloadContext);
  const [jackData, setJackData] = useState<JackData>({ data: [] });

  useEffect(() => {
    const getSearchData = async (param: JackSearchParam) => {
      const result = await postSearchJack(param);
      setload(false);
      setJackData(result);
      if (result?.isError) {
        await reloadContext?.setSnackbarInfo({
          isOpen: true,
          type: "error",
          message: result.message,
        });
      }
    };

    if (!searchParam) {
      getSearchData({
        jack_mei: "",
        jack_kokokunushi_mei: "",
        jack_haishinbi_from: "",
        jack_haishinbi_to: "",
        area_mei: "",
        location_mei: "",
        baitai_mei: "",
        is_haishin_zumi: false
      })
    } else {
      getSearchData(searchParam);
    }
  }, [searchParam, reloadContext?.reload]);

  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "ID",
      hide: true,
      filterable: false,
      disableColumnMenu: true,
    },
    {
      field: "jack_mei",
      headerName: "ジャック名",
      width: 250,
      sortable: false,
      disableColumnMenu: true,
      renderCell: renderCellExpand,
    },
    {
      field: "jack_kokokunushi_mei",
      headerName: "ジャック広告主名",
      width: 200,
      sortable: false,
      disableColumnMenu: true,
      renderCell: renderCellExpand,
    },
    {
      field: "shimei",
      headerName: "担当者",
      width: 150,
      sortable: false,
      disableColumnMenu: true,
      renderCell: renderCellExpand,
    },
    {
      field: "content_byosu",
      headerName: "契約秒数",
      width: 80,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => (
        renderCellExpand({ ...params, value: params.row.content_byosu })
      ),
    },
    {
      field: "jack_haishinbi",
      headerName: "ジャック配信日",
      width: 120,
      sortable: false,
      disableColumnMenu: true,
      renderCell: renderCellExpand,
    },
    {
      field: "jack_kaishijikan",
      headerName: "配信開始時間",
      width: 110,
      sortable: false,
      disableColumnMenu: true,
      renderCell: renderCellExpand,
    },
    {
      field: "update_dt",
      headerName: "更新日",
      width: 120,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: "status",
      headerName: "状態",
      width: 100,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        return <Typography component="h3" variant="subtitle2" sx={{ textAlign: "center" }}>
          {params.row.status === JACK_STATUS.SAKUSEICHU ?
            "作成中"
            : params.row.status === JACK_STATUS.HAISHINZUMI ?
              "配信済"
              : params.row.status === JACK_STATUS.HAISHIN_SHURYO ?
                "放映終了"
                : params.row.status === JACK_STATUS.MIHAISHIN ?
                  "未配信"
                  : ""
          }
        </Typography>
      }
    },
    {
      field: "is_content_toroku_kanryo",
      headerName: "コンテンツ登録完了",
      width: 150,
      sortable: false,
      disableColumnMenu: true,
      filterable: false,
      type: "boolean",
    },
    {
      field: 'editButton',
      headerName: "",
      width: 20,
      align: "center",
      sortable: false,
      disableColumnMenu: true,
      disableExport: true,
      renderCell: (params) => (
        <JackEditDialog jackId={params.row.id} />
      )
    },
    {
      field: "deleteButton",
      headerName: "",
      width: 20,
      align: "center",
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        // ステータスが配信終了、未配信かチェック
        let snackbar_info;
        if (params.row.status === JACK_STATUS.HAISHIN_SHURYO || params.row.status === JACK_STATUS.MIHAISHIN) {
          snackbar_info = {
            type: "warning",
            message: "配信終了、未配信のジャックは削除できません。",
          };
        }
        // 放映開始時間の24時間以内かチェック
        if (params.row.is_delete) {
          snackbar_info = {
            type: "warning",
            message: "ジャック放映開始時間の24時間以内は削除できません。",
          };
        }
        return <DeleteDialog deleteId={params.row.id} tableName="jack" params={{ snackbar_info: snackbar_info }} />
      },
    },
    {
      field: "shosaiButton",
      headerName: "",
      width: 100,
      align: "center",
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      disableExport: true,
      renderCell: (params) => {
        const handleClickDatail = () => {
          // 画面遷移
          navigate("/jackShosai", {
            state: {
              jack_id: params.row.id,
              jack_mei: params.row.jack_mei,
              searchParam
            }
          });
        };
        return <Button
          type="button"
          fullWidth
          variant="contained"
          onClick={() => handleClickDatail()}
        >
          詳細
        </Button>
      },
    },
  ]

  // ページのstate　１ページに１０行
  const [rowsState, setRowsState] = React.useState<RowsState>({
    page: 0,
    pageSize: 10,
  });

  const { data, rowCount } = useQuery(
    rowsState.page,
    rowsState.pageSize,
    jackData.data
  );

  // データの読み込みが間に合ってなくてrowCountがundefinedのままの場合の対策
  const [rowCountState, setRowCountState] = React.useState(rowCount || 0);
  React.useEffect(() => {
    setRowCountState((prevRowCountState) =>
      rowCount !== undefined ? rowCount : prevRowCountState
    );
  }, [rowCount, setRowCountState]);

  return (
    <div style={{ height: 650, width: "100%" }}>
      <DataGrid
        rows={data}
        columns={columns}
        rowCount={rowCountState}
        {...rowsState}
        rowsPerPageOptions={[10]}
        pagination
        paginationMode="server"
        sortModel={[{ field: "id", sort: "asc" }]}
        onPageChange={(page) => setRowsState((prev) => ({ ...prev, page }))}
        onPageSizeChange={(pageSize) =>
          setRowsState((prev) => ({ ...prev, pageSize }))
        }
        onSelectionModelChange={(selectionModel: GridSelectionModel) => {
          const jack = data.find((jack) => jack.id === selectionModel[0]);
          if (!jack) return;
          setSelectedRow(jack);
        }}
        localeText={jaJP.components.MuiDataGrid.defaultProps.localeText}
      />
    </div>
  );
};

export default JackGrid;