import { useState, useContext, useEffect } from "react";
import { useNavigate } from 'react-router-dom';

import {
  Menu,
  MenuItem,
  IconButton,
} from "@mui/material";

import { Upload } from '@mui/icons-material';

import {
  DataGrid,
  GridColDef,
  jaJP,
} from "@mui/x-data-grid";

import moment from "moment";

import HoeimoshikomiEditDialog from "./HoeimoshikomiEditDialog";
import BaitaiReadOnlyDialog from "./BaitaiReadOnlyDialog";
import { renderCellExpand } from "../Common/GridCellExpand";
import DeleteDialog from "../Common/DeleteDialog";
import DownloadConfirmDialog from "./DownloadConfirmDialog";

import { AuthInfoContext } from "../../context/AuthContext";
import { ReloadContext } from "../../context/ReloadContext";
import { KENGEN, UPLOAD_STATUS } from "../../const/index";
import { EXPIRED_MOSHIKOMI_KIGEN_NISSU } from "../../const/message";
import { HoeimoshikomiData, HoeimoshikomiDataSet, HoeimoshikomiSearchParam } from "../../types/WebData";
import { postSearchHoeimoshikomi } from "../../api/hoeimoshikomi";

/**
 * DataGrid用にデータを編集
 *
 * @param {HoeimoshikomiDataSet[]} hoeimoshikomiDatas
 * @return {*} 
 */
const makeGridData = (hoeimoshikomiDatas: HoeimoshikomiDataSet[]) => {
  return hoeimoshikomiDatas.map((bangumihyo) => {
    // 日付フォーマット
    const haishinKaishibi = moment(bangumihyo.haishin_kaishibi);
    const haishinShuryobi = bangumihyo.haishin_shuryobi ? moment(bangumihyo.haishin_shuryobi) : null;
    const updateDt = moment(bangumihyo.update_dt);
    return {
      ...bangumihyo,
      haishin_kaishibi: haishinKaishibi.format("YYYY/MM/DD"),
      haishin_shuryobi: haishinShuryobi ? haishinShuryobi.format("YYYY/MM/DD") : "",
      update_dt: updateDt.format("YYYY/MM/DD"),
    };
  });
};

/**
 * 全件データを１ページ分の行にsliceしている
 * 値に単位をつけるなどのデータの編集も、ここで１ページ分のみ行う
 */
const loadServerRows = (
  page: number,
  pageSize: number,
  allRows: HoeimoshikomiDataSet[]
): Promise<HoeimoshikomiDataSet[]> =>
  new Promise<HoeimoshikomiDataSet[]>((resolve) => {
    if (!allRows) return;
    resolve(
      // １ページ分にsliceした後に、データを編集するfunctionに渡している
      makeGridData(allRows.slice(page * pageSize, (page + 1) * pageSize))
    );
  });

const useQuery = (page: number, pageSize: number, allRows: any[]) => {
  const [rowCount, setRowCount] = useState<number | undefined>(undefined);
  const [data, setData] = useState<HoeimoshikomiDataSet[]>([]);

  useEffect(() => {
    let active = true;

    setRowCount(undefined);
    loadServerRows(page, pageSize, allRows).then((newRows) => {
      if (!active) {
        return;
      }
      setData(newRows);
      setRowCount(allRows.length);
    });

    return () => {
      active = false;
    };
  }, [page, pageSize, allRows]);

  return { data, rowCount };
};

interface RowsState {
  page: number;
  pageSize: number;
}

type Props = {
  searchParam: HoeimoshikomiSearchParam | null;
  setload: React.Dispatch<React.SetStateAction<boolean>>
};

/**
 * 放映申込一覧コンポーネント
 *
 * @param {Props} { searchParam }
 * @return {*} 
 */
const HoeimoshikomiGrid = ({ searchParam, setload }: Props) => {
  const navigate = useNavigate();
  const [hoeimoshikomiData, setHoeimoshikomiData] = useState<HoeimoshikomiData>({ data: [] });
  const [selectedRow, setSelectedRow] = useState<HoeimoshikomiDataSet>();
  const [authInfo] = useContext(AuthInfoContext);
  const reloadContext = useContext(ReloadContext);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  useEffect(() => {
    if (!searchParam) return;
    // 検索ボタン押下時
    const getSearchData = async () => {
      const result = await postSearchHoeimoshikomi(searchParam);
      if (result?.isError) {
        await reloadContext?.setSnackbarInfo({
          isOpen: true,
          type: "error",
          message: result?.message,
        });
      }
      setload(false);
      setHoeimoshikomiData(result);
    };

    getSearchData();
  }, [searchParam, reloadContext?.reload]);

  const handleMenu = (event: React.MouseEvent<HTMLElement>, row: any) => {
    setSelectedRow(row);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  /**
   * コンテンツアップロード画面遷移処理
   *
   * @param {boolean} henshuContentFlg
   * @param {*} [row]
   * @return {*} 
   */
  const handleClickUpload = async (henshuContentFlg: boolean, row?: any) => {
    const targetRow = authInfo.kengen === KENGEN.KANRI ? row : selectedRow;

    let moshikomiCheckFlg: boolean = false

    // 申込期限日数を過ぎていないかチェック処理の対象判定
    // ※権限：システム管理者、おまかせパックはチェックしない
    // ※おまかせパック媒体への申込は代理店権限、管理会社権限のみ行う
    // ※代理店権限で媒体管理会社以外の場合のみチェックを行う
    if (targetRow.omakase_pack_flg) {
      // おまかせパック媒体への申込の場合
      if (authInfo.kengen !== KENGEN.ADMIN && authInfo.kengen !== KENGEN.OMAKASE) {
        moshikomiCheckFlg = true;
      }
    } else {
      // おまかせパック媒体以外への申込の場合 
      if (authInfo.kengen === KENGEN.DAIRITEN && targetRow.kanri_kaisha_id !== authInfo.kaisha_id) {
        moshikomiCheckFlg = true;
      }
    }

    if (moshikomiCheckFlg) {
      // 媒体詳細毎の申込期限日数を過ぎていないかチェック
      const dateFrom = moment(targetRow.haishin_kaishibi).add(1, 'days');
      const dateTo = moment();
      const diffDays = dateFrom.diff(dateTo, 'days');
      if (diffDays < targetRow.moshikomi_kigen_nissu) {
        return await reloadContext?.setSnackbarInfo({
          isOpen: true,
          type: "warning",
          message: EXPIRED_MOSHIKOMI_KIGEN_NISSU,
        });
      }
    }

    navigate("/contentUpload",
      {
        state: {
          searchParam,
          henshu_content_flg: henshuContentFlg,
          selectedRow: authInfo.kengen === KENGEN.KANRI ? row : selectedRow
        }
      });
  }

  // ページのstate　１ページに１０行
  const [rowsState, setRowsState] = useState<RowsState>({
    page: 0,
    pageSize: 10,
  });

  const { data, rowCount } = useQuery(
    rowsState.page,
    rowsState.pageSize,
    hoeimoshikomiData.data
  );

  // データの読み込みが間に合ってなくてrowCountがundefinedのままの場合の対策
  const [rowCountState, setRowCountState] = useState(rowCount || 0);
  useEffect(() => {
    setRowCountState((prevRowCountState: number) =>
      rowCount !== undefined ? rowCount : prevRowCountState
    );
  }, [rowCount, setRowCountState]);

  const columns: GridColDef[] =
    authInfo.kengen === KENGEN.DAIRITEN ?
      [
        {
          field: "id",
          headerName: "ID",
          hide: true,
          filterable: false,
          disableColumnMenu: true,
        },
        {
          field: "baitai_mei",
          headerName: "媒体名",
          width: 200,
          sortable: false,
          disableColumnMenu: true,
          renderCell: renderCellExpand,
        },
        {
          field: 'mediaDialogButton',
          headerName: "",
          width: 10,
          minWidth: 10,
          align: "center",
          sortable: false,
          disableColumnMenu: true,
          renderCell: (params) => (
            <BaitaiReadOnlyDialog baitaiShosaiId={params.row.baitai_shosai_id} />
          ),
        },
        {
          field: "gamen_mei",
          headerName: "画面名",
          width: 200,
          sortable: false,
          disableColumnMenu: true,
          renderCell: renderCellExpand,
        },
        {
          field: "bangumi_mei",
          headerName: "番組名",
          width: 200,
          sortable: false,
          disableColumnMenu: true,
          renderCell: renderCellExpand,
        },
        {
          field: "kokyaku_mei",
          headerName: "広告主名",
          width: 180,
          sortable: false,
          disableColumnMenu: true,
          renderCell: renderCellExpand,
        },
        {
          field: "keiyaku_byosu",
          headerName: "契約秒数",
          width: 80,
          sortable: false,
          disableColumnMenu: true,
          renderCell: (params) => (
            renderCellExpand({ ...params, value: String(params.row.content_byosu * params.row.saisei_kaisu) })
          ),
        },
        {
          field: "haishin_kaishibi",
          headerName: "放映開始日",
          width: 100,
          sortable: false,
          disableColumnMenu: true,
        },
        {
          field: "haishin_shuryobi",
          headerName: "放映終了日",
          width: 100,
          sortable: false,
          disableColumnMenu: true,
        },
        {
          field: "content_file_mei",
          headerName: "提供ファイル名",
          width: 150,
          sortable: false,
          disableColumnMenu: true,
          cellClassName: (params) => {
            let className = "";
            className += params.row.upload_status === UPLOAD_STATUS.UPLOAD_FAILED ? "upload-failed " : "";
            className += !params.row.downloaded_flg ? "not-downloaded " : "";
            return className;
          },
          renderCell: renderCellExpand,
        },
        {
          field: "henshu_content_file_mei",
          headerName: "編集後ファイル名",
          width: 150,
          sortable: false,
          disableColumnMenu: true,
          cellClassName: (params) => {
            let className = "";
            className += params.row.henshu_upload_status === UPLOAD_STATUS.UPLOAD_FAILED ? "upload-failed " : "";
            return className;
          },
          renderCell: renderCellExpand,
        },
        {
          field: "update_dt",
          headerName: "更新日",
          width: 100,
          sortable: false,
          disableColumnMenu: true,
        },
        {
          field: 'uploadButton',
          headerName: "",
          width: 20,
          align: "center",
          sortable: false,
          disableColumnMenu: true,
          renderCell: (params) => (
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              disabled={authInfo.kaisha_id === params.row.kanri_kaisha_id ? params.row.omakase_pack_flg && authInfo.kaisha_id !== params.row.kaisha_id ? true : false : false}
              onClick={(event) => handleMenu(event, params.row)}
              color="inherit"
            >
              <Upload color={authInfo.kaisha_id === params.row.kanri_kaisha_id ? params.row.omakase_pack_flg && authInfo.kaisha_id !== params.row.kaisha_id ? "disabled" : "primary" : "primary"} fontSize='large' />
            </IconButton>
          )
        },
        {
          field: "downloadButton",
          headerName: "",
          width: 20,
          align: "center",
          filterable: false,
          sortable: false,
          disableColumnMenu: true,
          renderCell: (params) => (
            <DownloadConfirmDialog
              baitaiKanriKaishaId={params.row.kanri_kaisha_id}
              moshikomiKaishaId={params.row.kaisha_id}
              omakasePackFlg={params.row.omakase_pack_flg}
              contentId={params.row.content_id}
              henshuContentId={params.row.henshu_content_id}
              contentFilePath={params.row.content_file_path}
              henshuContentFilePath={params.row.henshu_content_file_path}
              contentFileMei={params.row.content_file_mei}
              henshuContentFileMei={params.row.henshu_content_file_mei}
            />
          ),
        },
        {
          field: "editButton",
          headerName: "",
          width: 20,
          align: "center",
          sortable: false,
          filterable: false,
          disableColumnMenu: true,
          renderCell: (params) => (
            <HoeimoshikomiEditDialog hoeimoshikomiId={params.row.id} isOpen={false}               
            isDisabled={authInfo.kengen !== KENGEN.ADMIN && (authInfo.kaisha_id === params.row.kanri_kaisha_id ? params.row.omakase_pack_flg && authInfo.kaisha_id !== params.row.kaisha_id ? true : false : false)}
            />
          ),
        },
        {
          field: "deleteButton",
          headerName: "",
          width: 20,
          align: "center",
          filterable: false,
          sortable: false,
          disableColumnMenu: true,
          renderCell: (params) => {
            // 媒体詳細毎の申込期限日数を過ぎていないかチェック
            const dateFrom = moment(params.row.haishin_kaishibi).add(1, 'days');
            const dateTo = moment();
            const diffDays = dateFrom.diff(dateTo, 'days');

            let snackbar_info;
            if (diffDays < params.row.moshikomi_kigen_nissu) {
              snackbar_info = {
                type: "warning",
                message: "申込期限日数を過ぎているため削除できません。",
              };
            }
            return <DeleteDialog
              baitaiKanriKaishaId={params.row.kanri_kaisha_id}
              moshikomiKaishaId={params.row.kaisha_id}
              omakasePackFlg={params.row.omakase_pack_flg}
              deleteId={params.row.id} tableName="hoeimoshikomi"
              params={{ snackbar_info: snackbar_info }} />
          },
        }
      ]
      :
      authInfo.kengen === KENGEN.KANRI ?
        [
          {
            field: "id",
            headerName: "ID",
            hide: true,
            filterable: false,
            disableColumnMenu: true,
          },
          {
            field: "baitai_mei",
            headerName: "媒体名",
            width: 200,
            sortable: false,
            disableColumnMenu: true,
            renderCell: renderCellExpand,
          },
          {
            field: 'mediaDialogButton',
            headerName: "",
            width: 10,
            minWidth: 10,
            align: "center",
            sortable: false,
            disableColumnMenu: true,
            renderCell: (params) => (
              <BaitaiReadOnlyDialog baitaiShosaiId={params.row.baitai_shosai_id} />
            ),
          },
          {
            field: "gamen_mei",
            headerName: "画面名",
            width: 200,
            sortable: false,
            disableColumnMenu: true,
            renderCell: renderCellExpand,
          },
          {
            field: "bangumi_mei",
            headerName: "番組名",
            width: 200,
            sortable: false,
            disableColumnMenu: true,
            renderCell: renderCellExpand,
          },
          {
            field: "kokyaku_mei",
            headerName: "広告主名",
            width: 180,
            sortable: false,
            disableColumnMenu: true,
            renderCell: renderCellExpand,
          },
          {
            field: "keiyaku_byosu",
            headerName: "契約秒数",
            width: 80,
            sortable: false,
            disableColumnMenu: true,
            renderCell: (params) => (
              renderCellExpand({ ...params, value: String(params.row.content_byosu * params.row.saisei_kaisu) })
            ),
          },
          {
            field: "haishin_kaishibi",
            headerName: "配信開始日",
            width: 100,
            sortable: false,
            disableColumnMenu: true,
          },
          {
            field: "haishin_shuryobi",
            headerName: "配信終了日",
            width: 100,
            sortable: false,
            disableColumnMenu: true,
          },
          {
            field: "henshu_content_file_mei",
            headerName: "コンテンツファイル名",
            width: 200,
            sortable: false,
            disableColumnMenu: true,
            cellClassName: (params) => {
              let className = "";
              className += params.row.henshu_upload_status === UPLOAD_STATUS.UPLOAD_FAILED ? "upload-failed " : "";
              return className;
            },
            renderCell: renderCellExpand,
          },
          {
            field: "update_dt",
            headerName: "更新日",
            width: 100,
            sortable: false,
            disableColumnMenu: true,
          },
          {
            field: 'uploadButton',
            headerName: "",
            width: 20,
            align: "center",
            sortable: false,
            disableColumnMenu: true,
            renderCell: (params) => (
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                disabled={authInfo.kaisha_id === params.row.kanri_kaisha_id ? params.row.omakase_pack_flg && authInfo.kaisha_id !== params.row.kaisha_id ? true : false : false}
                onClick={() => handleClickUpload(true, params.row)}
                color="inherit"
              >
                <Upload color={authInfo.kaisha_id === params.row.kanri_kaisha_id ? params.row.omakase_pack_flg && authInfo.kaisha_id !== params.row.kaisha_id ? "disabled" : "primary" : "primary"} fontSize='large' />
              </IconButton>
            )
          },
          {
            field: "downloadButton",
            headerName: "",
            width: 20,
            align: "center",
            filterable: false,
            sortable: false,
            disableColumnMenu: true,
            renderCell: (params) => (
              <DownloadConfirmDialog
                baitaiKanriKaishaId={params.row.kanri_kaisha_id}
                moshikomiKaishaId={params.row.kaisha_id}
                omakasePackFlg={params.row.omakase_pack_flg}
                contentId={params.row.content_id}
                henshuContentId={params.row.henshu_content_id}
                contentFilePath={params.row.content_file_path}
                henshuContentFilePath={params.row.henshu_content_file_path}
                contentFileMei={params.row.content_file_mei}
                henshuContentFileMei={params.row.henshu_content_file_mei}
              />
            ),
          },
          {
            field: "editButton",
            headerName: "",
            width: 20,
            align: "center",
            sortable: false,
            filterable: false,
            disableColumnMenu: true,
            renderCell: (params) => (
              <HoeimoshikomiEditDialog hoeimoshikomiId={params.row.id} isOpen={false}
              isDisabled={authInfo.kengen !== KENGEN.ADMIN && (authInfo.kaisha_id === params.row.kanri_kaisha_id ? params.row.omakase_pack_flg && authInfo.kaisha_id !== params.row.kaisha_id ? true : false : false)}
              />
            ),
          },
          {
            field: "deleteButton",
            headerName: "",
            width: 20,
            align: "center",
            filterable: false,
            sortable: false,
            disableColumnMenu: true,
            renderCell: (params) => (
              <DeleteDialog
                baitaiKanriKaishaId={params.row.kanri_kaisha_id}
                moshikomiKaishaId={params.row.kaisha_id}
                omakasePackFlg={params.row.omakase_pack_flg}
                deleteId={params.row.id}
                tableName="hoeimoshikomi" />
            ),
          }
        ]
        :
        [
          {
            field: "id",
            headerName: "ID",
            hide: true,
            filterable: false,
            disableColumnMenu: true,
          },
          {
            field: "baitai_mei",
            headerName: "媒体名",
            width: 200,
            sortable: false,
            disableColumnMenu: true,
            renderCell: renderCellExpand,
          },
          {
            field: 'mediaDialogButton',
            headerName: "",
            width: 10,
            minWidth: 10,
            align: "center",
            sortable: false,
            disableColumnMenu: true,
            renderCell: (params) => (
              <BaitaiReadOnlyDialog baitaiShosaiId={params.row.baitai_shosai_id} />
            )
          },
          {
            field: "gamen_mei",
            headerName: "画面名",
            width: 160,
            sortable: false,
            disableColumnMenu: true,
            renderCell: renderCellExpand,
          },
          {
            field: "bangumi_mei",
            headerName: "番組名",
            width: 200,
            sortable: false,
            disableColumnMenu: true,
            renderCell: renderCellExpand,
          },
          {
            field: "kokyaku_mei",
            headerName: "広告主名",
            width: 180,
            sortable: false,
            disableColumnMenu: true,
            renderCell: renderCellExpand,
          },
          {
            field: "kaisha_mei",
            headerName: "広告代理店名",
            width: 180,
            sortable: false,
            disableColumnMenu: true,
            renderCell: renderCellExpand,
          },
          {
            field: "shimei",
            headerName: "申込者",
            width: 150,
            sortable: false,
            disableColumnMenu: true,
            renderCell: renderCellExpand,
          },
          {
            field: "keiyaku_byosu",
            headerName: "契約秒数",
            width: 80,
            sortable: false,
            disableColumnMenu: true,
            renderCell: (params) => (
              renderCellExpand({ ...params, value: String(params.row.content_byosu * params.row.saisei_kaisu) })
            ),
          },
          {
            field: "haishin_kaishibi",
            headerName: "放映開始日",
            width: 100,
            sortable: false,
            disableColumnMenu: true,
          },
          {
            field: "haishin_shuryobi",
            headerName: "放映終了日",
            width: 100,
            sortable: false,
            disableColumnMenu: true,
          },
          {
            field: "content_file_mei",
            headerName: "提供ファイル名",
            width: 150,
            cellClassName: (params) => {
              let className = "";
              className += params.row.upload_status === UPLOAD_STATUS.UPLOAD_FAILED ? "upload-failed " : "";
              className += !params.row.downloaded_flg ? "not-downloaded " : "";
              return className;
            },
            sortable: false,
            disableColumnMenu: true,
            renderCell: renderCellExpand,
          },
          {
            field: "henshu_content_file_mei",
            headerName: "編集後ファイル名",
            width: 150,
            sortable: false,
            disableColumnMenu: true,
            cellClassName: (params) => {
              let className = "";
              className += params.row.henshu_upload_status === UPLOAD_STATUS.UPLOAD_FAILED ? "upload-failed " : ""
              return className;
            },
            renderCell: renderCellExpand,
          },
          {
            field: "update_dt",
            headerName: "更新日",
            width: 100,
            sortable: false,
            disableColumnMenu: true,
          },
          {
            field: 'uploadButton',
            headerName: "",
            width: 20,
            align: "center",
            sortable: false,
            disableColumnMenu: true,
            renderCell: (params) => (
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                disabled={authInfo.kengen !== KENGEN.ADMIN && (authInfo.kaisha_id === params.row.kanri_kaisha_id ? params.row.omakase_pack_flg && authInfo.kaisha_id !== params.row.kaisha_id ? true : false : false)}
                onClick={(event) => handleMenu(event, params.row)}
                color="inherit"
              >
                <Upload 
                  color={authInfo.kengen === KENGEN.ADMIN ? "primary" : (authInfo.kaisha_id === params.row.kanri_kaisha_id ? params.row.omakase_pack_flg && authInfo.kaisha_id !== params.row.kaisha_id ? "disabled" : "primary" : "primary")}
                  fontSize='large' />
              </IconButton>
            )
          },
          {
            field: "downloadButton",
            headerName: "",
            width: 20,
            align: "center",
            filterable: false,
            sortable: false,
            disableColumnMenu: true,
            renderCell: (params) => (
              <DownloadConfirmDialog
                baitaiKanriKaishaId={params.row.kanri_kaisha_id}
                moshikomiKaishaId={params.row.kaisha_id}
                omakasePackFlg={params.row.omakase_pack_flg}
                contentId={params.row.content_id}
                henshuContentId={params.row.henshu_content_id}
                contentFilePath={params.row.content_file_path}
                henshuContentFilePath={params.row.henshu_content_file_path}
                contentFileMei={params.row.content_file_mei}
                henshuContentFileMei={params.row.henshu_content_file_mei}
              />
            )
          },
          {
            field: 'editButton',
            headerName: "",
            width: 20,
            align: "center",
            sortable: false,
            disableColumnMenu: true,
            renderCell: (params) => {
              return <HoeimoshikomiEditDialog hoeimoshikomiId={params.row.id} isOpen={false} 
              isDisabled={authInfo.kengen !== KENGEN.ADMIN && (authInfo.kaisha_id === params.row.kanri_kaisha_id ? params.row.omakase_pack_flg && authInfo.kaisha_id !== params.row.kaisha_id ? true : false : false)}
              />
            }
          },
          {
            field: "deleteButton",
            headerName: "",
            width: 20,
            align: "center",
            filterable: false,
            sortable: false,
            disableColumnMenu: true,
            renderCell: (params) => (
              <DeleteDialog
                baitaiKanriKaishaId={params.row.kanri_kaisha_id}
                moshikomiKaishaId={params.row.kaisha_id}
                omakasePackFlg={params.row.omakase_pack_flg && authInfo.kengen !== KENGEN.ADMIN}
                deleteId={params.row.id}
                tableName="hoeimoshikomi" />
            ),
          }
        ];

  return (
    <div style={{ height: 650, width: "100%" }}>
      <DataGrid
        rows={data}
        columns={columns}
        rowCount={rowCountState}
        pagination
        paginationMode="server"
        rowsPerPageOptions={[10]}
        {...rowsState}
        sortModel={[{ field: "create_dt", sort: "desc" }]}
        sx={{ '.not-downloaded': { color: "red" }, '.upload-failed': { color: "red", textDecoration: "line-through" } }}
        onPageChange={(page) => setRowsState((prev) => ({ ...prev, page }))}
        onPageSizeChange={(pageSize) =>
          setRowsState((prev) => ({ ...prev, pageSize }))
        }
        localeText={jaJP.components.MuiDataGrid.defaultProps.localeText}
      />
      <Menu
        id={`menu-appbar`}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={() => handleClickUpload(false)}>コンテンツアップロード</MenuItem>
        <MenuItem onClick={() => handleClickUpload(true)}>編集コンテンツアップロード</MenuItem>
      </Menu>
    </div>
  );
};

export default HoeimoshikomiGrid;
